<template>
  <CustomButton type="secondary-dark-alt" shape="pill" size="sm" :text-small="true" icon-position="left-between" icon-size="sm">
    <span class="max-[1500px]:hidden min-[1501px]:block">{{ t('zeit_storefront.layout.header.actions.cart') }} ({{ lineItemsCount }})</span>
    <template #icon>
      <svg class="mr-[-0.5rem] min-[1500px]:mr-0" width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M1.79879 1.79831C1.95822 1.63888 2.17445 1.54932 2.39991 1.54932H13.6006C13.8261 1.54932 14.0423 1.63888 14.2018 1.79831C14.3612 1.95773 14.4507 2.17396 14.4507 2.39942V13.6002C14.4507 13.8256 14.3612 14.0418 14.2018 14.2013C14.0423 14.3607 13.8261 14.4503 13.6006 14.4503H2.39991C2.17445 14.4503 1.95822 14.3607 1.79879 14.2013C1.63937 14.0418 1.5498 13.8256 1.5498 13.6002V2.39942C1.5498 2.17396 1.63937 1.95773 1.79879 1.79831ZM2.39991 0.0493164C1.77662 0.0493164 1.17886 0.296916 0.738134 0.737646C0.297404 1.17838 0.0498047 1.77613 0.0498047 2.39942V13.6002C0.0498047 14.2234 0.297404 14.8212 0.738134 15.2619C1.17886 15.7027 1.77662 15.9503 2.39991 15.9503H13.6006C14.2239 15.9503 14.8217 15.7027 15.2624 15.2619C15.7031 14.8212 15.9507 14.2234 15.9507 13.6002V2.39942C15.9507 1.77613 15.7031 1.17838 15.2624 0.737646C14.8217 0.296916 14.2239 0.0493164 13.6006 0.0493164H2.39991ZM5.95153 4.00106C5.95153 3.58684 5.61574 3.25106 5.20153 3.25106C4.78731 3.25106 4.45153 3.58684 4.45153 4.00106C4.45153 4.46727 4.54335 4.92892 4.72177 5.35965C4.90018 5.79038 5.16169 6.18175 5.49135 6.51141C5.82101 6.84108 6.21238 7.10258 6.64311 7.281C7.07384 7.45941 7.53549 7.55124 8.00171 7.55124C8.46792 7.55124 8.92957 7.45941 9.3603 7.281C9.79103 7.10258 10.1824 6.84108 10.5121 6.51141C10.8417 6.18175 11.1032 5.79038 11.2816 5.35965C11.4601 4.92892 11.5519 4.46727 11.5519 4.00106C11.5519 3.58684 11.2161 3.25106 10.8019 3.25106C10.3877 3.25106 10.0519 3.58684 10.0519 4.00106C10.0519 4.27029 9.99886 4.53689 9.89583 4.78563C9.7928 5.03437 9.64178 5.26038 9.45141 5.45075C9.26103 5.64113 9.03502 5.79215 8.78628 5.89518C8.53754 5.99821 8.27094 6.05124 8.00171 6.05124C7.73247 6.05124 7.46588 5.99821 7.21714 5.89518C6.9684 5.79215 6.74239 5.64113 6.55201 5.45075C6.36163 5.26038 6.21062 5.03437 6.10759 4.78563C6.00456 4.53689 5.95153 4.27029 5.95153 4.00106Z" fill="black"/>
      </svg>
    </template>
  </CustomButton>
</template>

<script setup>
import {useTranslations} from "@i18n/utils.js";
import {useCart} from "@shopware/cart/index.js";
import {calculateTotalCountFromCartLineItems} from "@shopware/helper/cart/index.js";
import {cartCurrentLineItemsCount} from "@store/globalStore.js";
import CustomButton from "@components/vue-elements/CustomButton.vue";
import {ref, onMounted} from "vue";

const {t} = useTranslations();
const {getCart} = useCart();

const lineItemsCount = ref(0);

onMounted(() => {
  loadQuantity();
});

cartCurrentLineItemsCount.listen(async (value) =>  {
  if (value === true || value === false) {
    await loadQuantity();
    return;
  }

  lineItemsCount.value = value;
});

const loadQuantity = async () => {
  const cart = await getCart();
  lineItemsCount.value = calculateTotalCountFromCartLineItems(cart?.lineItems);
};
</script>